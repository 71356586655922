import "./about.css";

export default function About() {
    return (
        <section className="about" id="about">
            <div className="aboutWrapper">
                <div className="aboutBox aboutBoxLeft">
                    <h2 className="aboutTitle">
                        <span>HATÉKONY FERTŐTLENÍTÉS</span>
                        Az ózonos tisztítással védjük egészségét!
                    </h2>
                </div>
                <div className="aboutBox aboutBoxRight">
                    <div className="aboutDesc">
                        <p className="aboutDescNormal">
                            Ózongenerátoros fertőtlenítést végzünk, amellyel zárt térben hatékonyan elpusztítjuk a baktériumokat, gombákat, vírusokat, köztük kutatások igazolják, hogy a koronavírussal szemben is hatásosan fellép ez az eljárás.  Az egyes allergének (por, vegyszerek) okozta tünetek is enyhíthetőek vagy akár teljesen megszüntethetőek.
                        </p>
                        <p className="aboutDescNormal">
                            Védje Ön is saját és környezete egészségét! 
                        </p>
                        <p className="aboutDescNormal">
                            Otthona, autója, vállalkozása vagy bármilyen zárt tér ózonos tisztítására vegye igénybe ózongenerátoros fertőtlenítésünket!
                        </p>
                        <p className="aboutDescBold">
                            Miért hatékony ez a módszer?
                        </p>
                        <ul className="aboutDescList">
                            <li>
                                Mert a hagyományos vegyszerekkel ellentétben nem tudnak ellene immunitást felépíteni a baktériumok, vírusok és gombák.
                            </li>
                            <li>
                                Mert a fennmaradó ózon idővel oxigénre bomlik, így megfelelő levegőcserét követően nem okoz káros mellékhatásokat.
                            </li>
                        </ul>
                        <p className="aboutDescNormal">
                            Ezért javasoljuk jó szívvel, hiszen ismerjük, tudjuk a működési folyamatait és sok esetben alkalmaztuk már hatékonyan.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
