import "./questions.css";

export default function Questions() {
    return (
        <section className="questions">
            <h3 className="questionsTitle">Kérdések és válaszok</h3>
            <span className="questionsIntro">
                Összegyűjtöttünk néhány kérdést és választ, amely segít eligazodni az ózonos tisztítás folyamatában.
            </span>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Hol lehet használni az ózonos tisztítást?
                </h4>
                <p className="questionDesc">
                    Minden olyan területen alkalmazható az ózongenerátoros fertőtlenítés, amelyet akár csak átmenetileg is, de le lehet zárni. Ez azért szükséges, hogy a helyiség megtölthető legyen a generátorok által előállított ózonnal. Ez a kulcsa annak, hogy hatékonyan fertőtleníthető legyen a terület.
                </p>
            </div>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Hol lehet használni az ózonos tisztítást?
                </h4>
                <p className="questionDesc">
                    Minden olyan területen alkalmazható az ózongenerátoros fertőtlenítés, amelyet akár csak átmenetileg is, de le lehet zárni. Ez azért szükséges, hogy a helyiség megtölthető legyen a generátorok által előállított ózonnal. Ez a kulcsa annak, hogy hatékonyan fertőtleníthető legyen a terület.
                </p>
            </div>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Hol lehet használni az ózonos tisztítást?
                </h4>
                <p className="questionDesc">
                    Minden olyan területen alkalmazható az ózongenerátoros fertőtlenítés, amelyet akár csak átmenetileg is, de le lehet zárni. Ez azért szükséges, hogy a helyiség megtölthető legyen a generátorok által előállított ózonnal. Ez a kulcsa annak, hogy hatékonyan fertőtleníthető legyen a terület.
                </p>
            </div>
            <div className="questionBox">
                <h4 className="questionSubtitle">
                    Hol lehet használni az ózonos tisztítást?
                </h4>
                <p className="questionDesc">
                    Minden olyan területen alkalmazható az ózongenerátoros fertőtlenítés, amelyet akár csak átmenetileg is, de le lehet zárni. Ez azért szükséges, hogy a helyiség megtölthető legyen a generátorok által előállított ózonnal. Ez a kulcsa annak, hogy hatékonyan fertőtleníthető legyen a terület.
                </p>
            </div>
        </section>
    )
}
