import "./services.css";
import LivingRoom from "../../images/living-room.jpg";
import Classroom from "../../images/classroom.jpg";
import Office from "../../images/office.jpg";
import Hospital from "../../images/hospital.jpg";
import Industry from "../../images/industry.jpg";
import Car from "../../images/car.jpg";
import ServiceBox from "./ServiceBox";

export default function Services() {
    return (
        <section className="services" id="services">
            <h2 className="servicesTitle">Biztonságos fertőtlenítés</h2>
            <p className="servicesIntro">
                Az ózonos tisztítás nagyon sokoldalú fertőtlenítést tesz lehetővé, bizonyos helyeken azonban szinte elengedhetetlen. Mivel teljesen biztonságos és hatékony eljárás Ön és környezete egészsége védelmére, így ezeken a területek feltétlenül javosoljuk.
            </p>
            <div className="serviceBoxes">
                <ServiceBox 
                    src={LivingRoom} 
                    title="Házak, lakások" 
                    desc="Életünk másik meghatározó része a munkahely, illetve közösségi tér, intézmény.
                    Ráadásul itt többen is lehetünk, mint otthon, családi környezetben, ezért szerintük nélkülözhetetlen  az ilyen terek fertőtlenítése ózonos tisztítással."
                    descTwo="Az ózongenerátoros fertőtlenítés ugyanis elpusztítja a vírusokat, penészt, gombákat, baktériumokat, szagmolekulákat, valamint a poratkákat is. Az általános takarítást azonban nem helyettesíti! A felületi szennyeződéseket, szagforrásokat nem tudja eltávolítani, így javasolt egy általános fertőtlenítő takarítás annak érdekében, hogy az ózonos tisztítást követően magasabb fokú legyen a higiénia otthonában."
                    price="200 Ft / m2"
                />
                <ServiceBox 
                    src={Car} 
                    title="Járművek utastere és raktere" 
                    desc="Hétköznapi életünk következő színtere az autónk.
                    Akár magáncélra, akár utaztatásra, vagy vállalkozásként használja itt is érdemes ügyelnie a higiéniára."
                    descTwo="A gépjárművek utastere (kezelőszervek, ülések, kárpitok, klímarendszer) ugyanis kiváló táptalajt biztosít a mikroorganizmusok megtelepedésére. Az ózonos tisztítás révén a jármű belsejének megbontása nélkül lehet kiválóan fertőtleníteni."
                    price="5000 Ft-tól"
                />
                <ServiceBox 
                    src={Classroom} 
                    title="Közösségi terek" 
                    desc="Rendkívül hasznos szolgálatot tehet a rendszeres ózongenerátoros fertőtlenítés az iskolákban, óvodákban, bölcsődékben, vagy bármely olyan intézményben, ahol sokan fordulnak meg egy nap, jelen esetben főként gyermekek. Vírusos időszakban, vagy több napos szünetek esetén javasolt a rendszeres fertőtlenítés."
                    descTwo="Ezeken a helyeken ugyanis nagyobb a lehetősége annak, hogy szemmel nem látható szennyeződések találhatóak a levegőben és a különböző felületeken egyaránt. Az ilyen intézményekben, helyiségekben, épületekben, rendkívül jó szolgálatot tesz az ózongenerátoros fertőtlenítés, mivel több ember számára biztosít tiszta területet, fokozott biztonságot a különböző kórokozókkal szemben. Ilyen területek lehetnek az iskolák, óvodák, szállodák, kórházak, rendelők, irodák, irodaházak, üzletek, könyvtárak, edzőtermek, uszodák stb."
                    price="150 Ft / m2"
                />
                <ServiceBox 
                    src={Industry} 
                    title="Ipari létesítmények, csarnokok" 
                    desc="Felmerülhet a feltételezés, hogy egyes ipari létesítmények, vagy csarnokok nem bonyolítanak le akkora
                    forgalmat, mint a korábban felsorolt területek, így nincs létjogosultsága az ózonos fertőtlenítésnek. Ez a
                    feltételezés nem helytálló, mivel az ilyen jellegű építmények fertőtlenítése is kiemelten fontos. Az esetek
                    nagy részében ugyanis munkahelyként, dolgozói térként szolgálnak, így fertőtlenítéssel biztonságosabb
                    munkakörülmények teremthetőek meg, hasonlóan a közösségi terekhez."
                    descTwo="A raktárként használt épületek, csarnokok esetében pedig az ott tárolt, oda szállított árukészlet a legtöbb esetben rengeteg kontakot követően kerül a végleges helyére, vagy akár a vevőkhöz. Ebből kifolyólag
                    kiemelten fontos szerepet kap a csomagolások felszínének ózonos fertőtlenítése, hogy a vásárlók, vagy a
                    raktárkészletet újfent mozgató emberek egy biztonságosan, hatékonyan fertőtlenített felülettel
                    érintkezzenek."
                    price="130 Ft / m2"
                />
            </div>
        </section>
    )
}
