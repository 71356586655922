import "./intro.css";
import House from "../../videos/house.mp4";
import Car from "../../videos/car.mp4";
import Bar from "../../videos/bar.mp4";
import Arrow from "../../images/arrow.png";
import { useState } from "react";

export default function Intro() {

    const [index, setIndex] = useState(1);

    return (
        <section className="intro" id="intro">
            <div className="introVideos">
                <video 
                    className="introVideo" 
                    autoPlay 
                    muted 
                    loop
                    style={index === 1 ? {display: "block"} : {display: "none"}}
                >
                    <source src={House} type="video/mp4" />
                </video>
                <video 
                    className="introVideo" 
                    autoPlay 
                    muted 
                    loop
                    style={index === 2 ? {display: "block"} : {display: "none"}}
                >
                    <source src={Car} type="video/mp4" />
                </video>
                <video 
                    className="introVideo" 
                    autoPlay 
                    muted 
                    loop
                    style={index === 3 ? {display: "block"} : {display: "none"}}
                >
                    <source src={Bar} type="video/mp4" />
                </video>
            </div>
            <div className="introDesc">
                <div className="introCenter">
                    <div className="prevBtn introBtn"
                         onClick={() => index === 3 ? setIndex(1) : setIndex(index + 1)}
                    >
                        <i className="fas fa-angle-double-left"></i>
                    </div>
                    <div className="introTitle">
                        {
                            index === 1 ? <span>Házak<br></br>lakások</span> : 
                            index === 2 ? <span>Járművek<br></br>utastere</span> : 
                                          <span>Közösségi<br></br>terek</span>
                        }
                    </div>
                    <div className="nextBtn introBtn"
                         onClick={() => index === 3 ? setIndex(1) : setIndex(index + 1)}
                    >
                        <i className="fas fa-angle-double-right"></i>
                    </div>
                </div>
                <div className="introText">
                    {
                        index === 1 ? 
                            "Az otthoni higiénia egy rendkívül fontos tényező. Az ózongenerátoros fertőtlenítés elpusztítja a vírusokat, penészt, gombákat, baktériumokat, szagmolekulákat." 
                        : index === 2 ? 
                            "A gépjárművek utastere kiváló táptalajt biztosít a mikroorganizmusok megtelepedésére. Az ózonos fertőtlenítés révén a jármű belsejében kiváló higiéniát lehet elérni." 
                        :
                            "A közösségi terek fertőtlenítése is rendkívül fontos, mivel az ember egy napja nagy részét valamely ilyen intézményben tölti"
                    }
                </div>
                <img className="introArrow" src={Arrow} alt="" />
            </div>
        </section>
    )
}